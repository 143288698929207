import _ from 'lodash';
import React from 'react';
import { Tooltip } from 'antd';

const PACKAGING_TOOLTIPS = {
  packaging_product_hierarchy_level: `Describes the hierarchical level of the trade item. A trade item can exist in different configurations (e.g., base units/each, case, or bulk packaging (display shippers, pallet) and each configuration will have its own GTIN.
 
Base Unit or Each: The lowest level of the item hierarchy intended or labeled for individual resale.
Case: A standard trade item shipping unit that contains a single or multiple GTINs within.
Pack or Inner Pack: A logistical unit between Case and Each.
Pallet:  A unit load to transport multiples of a product (eg a Pallet is a shipping format for multiple Cases).

For GS1 suppliers, refers to tradeItemUnitDescriptorCode in GS1.
`,
  packaging_level: `Primary Packaging, also referred to as “retail” or “consumer” packaging is the packaging that contains the product.

Examples of Primary Packaging include:
•	#10 cans for shelf stable items
•	Bottles used individual liquid consumption of soft drinks or water
•	Paperboard cartons containing beverages
•	Plastic sleeves protecting individual packs of disposable items


Secondary packaging refers to the boxes or containers surrounding specific quantities of primary packaging. It is used to ship or display multiple units of primary packaging.

Examples of Secondary Packaging include:
•	Cardboard boxes and cases
•	Shipping cartons
•	Shrink wraps and outer films surrounding groups of primary packaging


Tertiary Packaging refers to the bulk transportation method of products such as pallets and large shipping containers. It is often used for protecting, storing, and warehousing grouped quantities of secondary packaging.

Examples of Secondary Packaging include:
•	Pallets
•	Wooden crates
•	Strapping
•	Stretch/shrink wraps surrounding pallets

For GS1 suppliers, refers to packagingLevelTypeCode.`,
  packaging_weight: `Used to identify the measurement of the packaging weight of the trade item. Refers to the weight of the specified packaging alone, not the total weight (gross weight) of the packaging and the trade item.
  
  For GS1 suppliers, refers to packagingWeight.`,
  packaging_type: `The code for the type of package or container of the product (the dominant means used to transport, store, handle or display the trade item). 

Used in conjunction with the Packaging Material to provide information to the buyer on the type of product packaging for business process such as space planning, supply chain processes, recycling processes.
This packaging is not used to describe any manufacturing process. 

For GS1 suppliers, refers to packagingTypeCode.
`,
  packaging_material_type: `The materials used for the packaging of the trade item for example glass, corrugates, or plastics.

For GS1 suppliers, refers to packagingMaterialTypeCode.`,
  packaging_feature: `A packaging feature that facilitates the usage of the product by the consumer. Features do not affect the core composition of the packaging type nor modify its usage.

Examples: Twist off Cap, Handle, Pull off Tab, Internal Divider

For GS1 suppliers, refers to PackagingFeatureCode.`,
  packaging_component_description: `Part of packaging that can be separated by hand or by using simple physical means, for example a packaging film.

For GS1 suppliers, refers to PackagingComponentDescription.`,
  packaging_is_returnable: `Packaging Part can be returned - indicates that specifies whether the product packaging is marked as returnable (with or without a deposit).

For GS1 suppliers, refers to isPackagingReturnable.`,
  packaging_post_consumer_recyclability: `The ratio of Pre/Post Consumer recycled material to total material used in Packaging Part (type, feature, or component).

For GS1 suppliers, refers to packagingRecycledContentRatio.`,
  packaging_post_consumer_recyclable_type: `The type of Pre/Post Consumer recycled content that is being measured for a Packaging Part.

For GS1 suppliers, refers to packagingRecycledContentTypeCode.`,
  packaging_sustainability_claim: `A feature of the packaging that contributes to sustainability initiatives for example that it is made from renewable materials.

For GS1 suppliers, refers to packagingSustainabilityFeatureCode.`,
  packaging_post_industrial_recyclability: `The ratio of Pre/Post Industrial recycled material to total material used in Packaging Part (type, feature, or component).`,
  packaging_post_industrial_recyclable_type: `The type of Pre/Post Industrial recycled content that is being measured for a Packaging Part.`,
  packaging_how_2_recycle: `Notes How2Recycle Label on trade item's packaging. 

How2Recycle is a standardized labeling system that provides clear and consistent recycling instructions for packaging. It helps consumers properly dispose of packaging materials by providing guidance on recycling symbols and instructions. How2Recycle encourages recycling and supports waste reduction efforts by promoting correct disposal practices and minimizing contamination in recycling streams.`,
  packaging_bpi_certified: `Notes BPI certification for part or whole of trade item' packaging and not the trade item itself. 

The Biodegradable Products Institute (BPI) is a not-for-profit association using scientifically based standards to identify  compostable materials which biodegrade in large composting facilities.`,
  packaging_fsc_certified: `Notes FSC certification for part or whole of trade item's packaging and not the trade item itself. 

FSC is a global, not-for-profit organization dedicated to the promotion of responsible forest management worldwide. Certification notes packaign made with material from FSC certified forests , recycled materials or other controlled sources.`,
  packaging_pefc_certified: `Notes PEFC certification for part or whole of trade item's packaging and not the trade item itself. 

Programme for Endorsement of Forest Certification (PEFCC_ is an international non-profit, non-governmental organisation dedicated to promoting Sustainable Forest Management (SFM) through independent third-party certification.`,
  packaging_sfi_certified: `Notes SFI certification for part or whole of trade item's packaging and not the trade item itself. 

The Sustainable Forestry Initiative (SFI) is an independent, non-profit organization responsible for maintaining, overseeing and improving a sustainable forestry certification program.`,
  packaging_austria_ok_compost_certified: `Notes TUV OK Compost Certification for part or whole of trade item's packaging and not the trade item itself.

Packaging or products bearing the OK compost INDUSTRIAL or HOME labels are guaranteed to be biodegradable in an industrial composting plant. This applies to all of their components, inks and additives.  Any packaging  bearing the OK compost INDUSTRIAL logo complies with the requirements of the EU Packaging Directive (94/62/EEC).`
};

const PackageTooltipInfo = ({ stepId }) => {
  const tooltip = PACKAGING_TOOLTIPS[stepId] || null;

  if (!_.isEmpty(tooltip)) {
    return (
      <Tooltip
        overlayClassName={'packaging-tooltips'}
        style={{ width: '500px' }}
        title={
          <div style={{ paddingLeft: '15px', paddingRight: '15px' }} className="gdsn-info">
            {tooltip.split('\n').map((line, index) => (
              <span key={index}>
                {line}
                <br />
              </span>
            ))}
          </div>
        }
      >
        <div className="tool-tip-i">i</div>
      </Tooltip>
    );
  } else {
    return <div />;
  }
};

export default PackageTooltipInfo;
